@use 'base/variables' as v;
@use 'base/mixins' as m;


.secBeneficios { 

    width: 100%;
    height: auto;
    position: relative;
    background: v.$color4;

    @include m.mixFlex(flex,center,center,false,column);
    color: v.$blanco;

    h4 { 
        margin: 3rem auto; 
        width: 90%;
        text-align: center;
    }
}
