@use 'base/variables' as v;
@use 'base/mixins' as m;


.secPagos {

    position: relative;
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    color: v.$negro;

    @include m.mixFlex(flex,center,center,false,column);

    @include m.mixMQ(v.$desk1024) {
        width: 90%;
    };


    .payment-instructions {
        margin-bottom: 4rem;

        .instruction-section {

            i {
                color: v.$color1;
            }
        }
    }

}
