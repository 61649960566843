@use 'base/variables' as v;
@use 'base/mixins' as m;


.secSlider {

    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    .sliderbar_container {
        width: 90%;
        margin: 3rem auto 2rem;
        overflow: hidden;
    }

    .controller {
        margin-top: 2rem;
    }
     
    
    .modulo-container {
        max-width: 400px;
        height: 400px;
        background: v.$degradadoRed;
        border-radius: 10px;
        box-shadow: v.$box-shadow;
        margin: 0 1rem;
        // @include m.mixFlex(flex,center,center,nowrap,column);

        .youtube-container {
            // position: relative;
            width: 100%;
            height: 50%;
            padding: 0;
            overflow: hidden;
        
            .youtube-video {

                // position: absolute;            
                // top: 0;
                // left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
        
                iframe {

                    width: 100%;
                    height: 100%;
                    border-radius: 10px 10px 0 0;

                }
            
            }        
        
            .youtube-portada {
                // position: absolute;            
                // top: 0;
                // left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
        
                img {
                    // position: absolute;
                    // width: 100%;
                    height: 100%;
                    border-radius: 10px 10px 0 0;
                    object-fit: cover;
                    // box-shadow: v.$box-shadow;
        
                    @include m.mixMQ(v.$tablet) {
                        height: 100%;
                    };
                }
            
            }        
        
        }    
    
        .modulo-descrip {
            padding: 2rem;
            color: v.$blanco;
            height: 50%;
            overflow: hidden;

            p {
                padding: 0;
                color: v.$blanco;
            }
        }

    }

    .slide-testimonio-video {

        // width: 400px;
        // height: 400px;
        background: v.$degradadoRed;
        border-radius: 10px;
        // box-shadow: v.$box-shadow2;
        position: relative;
        margin-right: 2rem;

        img {
            width: 100%;
            object-fit: cover;
            box-shadow: v.$box-shadow2;
            border-radius: 10px;
        }

    }
    
    .resena-container { 
        position: relative;
        scroll-snap-align: center;
        background-color: #fff;
        width: 90%;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: v.$box-shadow;
        padding: 3rem;
        
        .fa-quote-right {
            position: absolute;
            top: 45%; right: 1rem;
            font-size: 3rem;
            color: v.$color2;            
        }
    
        
        .nr-cliente {
            @include m.mixFlex(flex,left,center,nowrap,row);

            .cliente-logo {
                width: 125px;
                height: 125px;
                overflow: hidden;
                box-shadow: v.$box-shadow;
                border-radius: 50%;
                margin-right: 2rem;
                padding: 0;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .cliente-info {

                h4 {
                    margin-bottom: 0;
                    color: v.$negro;
                }

                small {
                    font-size: 1.2rem;
                    display: block;
                    text-transform: uppercase;

                    span {
                        font-style: bolder;
                    }
                }
    
                .stars {
                    margin-top: 1rem;
                    i {
                        font-size: 1.5rem;
                        color: v.$color1;
                    }
                }

            }

        }
    
        .nva-resena-comentario {
            padding: 2rem;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            // text-align: center;
    
            p {
                font-size: 1.8rem;
            }
    
            &::-webkit-scrollbar {
                width: 8px;     /* Tamaño del scroll en vertical */
                height: 8px;    /* Tamaño del scroll en horizontal */
                /* display: none;  Ocultar scroll */
            }
            
            /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
            &::-webkit-scrollbar-thumb {
                background: v.$color1;
                border-radius: 4px;
            }
    
            /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
            &::-webkit-scrollbar-thumb:hover {
                // background: #b3b3b3;
                box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
            }
    
            /* Cambiamos el fondo cuando esté en active */
            &::-webkit-scrollbar-thumb:active {
                background: v.$color1;
            }
            
            &::-webkit-scrollbar-track {
                /* background: #e1e1e1; */
                border-radius: 4px;
            }
    
            /* Cambiamos el fondo cuando esté en active o hover */
            &::-webkit-scrollbar-track:hover,
            &::-webkit-scrollbar-track:active {
                background: #d4d4d4;
            }        
    
        }
    }

    .content-gallery {
        position: relative;
        margin: 3rem 0 0;
        top: 2px;
        margin-left: auto;
        margin-right: auto;
        width: 450px;
        height: 450px;
        overflow: hidden;        
        box-shadow: v.$box-shadow;
        border-radius: 10px;

    }

    .icoDrag {
        margin: 2rem auto 0;        

        width: 40px;
        // margin: 0 auto;
    
        img {
            width: 40px;
            animation: dragico 2s infinite;
        }
    
    }

    .slide-galeria {
        margin: 0 1rem;

        img {
            width: 100%;
            object-fit: cover;
            box-shadow: v.$box-shadow2;
            border-radius: 10px;
        }

    }
    
    .sec-boton {
        text-align: center;
        margin: 2rem auto;
    }

}




