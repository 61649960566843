@use 'variables' as v;
@use 'mixins' as m; 

.widgetWP {
    position: fixed;
    width: 165px;
    left: -30px;
    bottom: 20px;
    z-index: 10000;

    a {
        width: 100%;
        text-align: right;
    }
}

