@use 'base/variables' as v;
@use 'base/mixins' as m;

.datos-curso {
    width: 100%;
    max-width: 1200px;
    // margin-top: 3rem;

    padding: 0; 

    .detalle-curso {

        width: 90%;
        margin: auto;

        p {
            margin-top: 2rem;
            padding-bottom: 2rem;
            line-height: 2.25rem;
        }

    }

    .comprar-curso {

        margin: 0 auto 7rem;
        width: 90%;
        max-width: 1200px;

        @include m.grid(2,1);    
        
        @include m.mixMQ(v.$movil600) {
            @include m.grid(1,1);                
        };

        .comprar {

            position: relative;
            padding: 3rem;
            color: v.$blanco;
            background-color: v.$color4;
            margin: 2rem;
            border-radius: 7px;
            height: auto;

            @include m.mixMQ(v.$movil) {
                margin: 2rem 0;
            };

            h3 {
                font-size: 3.5rem!important;
                line-height: 1.2;

                @include m.mixMQ(v.$desk1024) {
                    font-size: 3rem!important;
                };
            }

            p {
                color: v.$blanco;
            }

            h4 {
                margin-bottom: 0!important;
            }

            small {
                padding: 0;
            }

            .boton, .botonGris {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 250px;
                transform: translate(-50%, 55%);
                text-align: center;
            }

            .botonGris:hover {
                cursor:not-allowed;
                color: v.$color3!important;
                border: none;            
            }

            &.suscripcion {
                background: v.$degradadoRed!important;
            }
        }
    }

    .lecciones {

        padding: 0;
        width: 90%;
        max-width: 1200px;
        margin: 5rem auto;

        
        h2 {
            text-align: center;
            margin-bottom: 3rem;

        }
      
        .table { 
            width: 100%;
            border-collapse: collapse;
        
            th, td {
                padding: 1rem;
                border: 1px solid #ddd;
                text-align: center;

                @include m.mixMQ(v.$movil414) {
                    font-size: 1.3rem;
                };
            }
        
            th {
                background-color: #f4f4f4;
            }

        
            .btn-play {
                background-color: #007bff;
                color: white;
                padding: 0.5rem 1rem;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                // font-size: 1.5rem;
                
                &:hover {
                    background-color: #0056b3;
                }
            }
    
            .estatus {
                padding: 0.5rem 1rem;
                border: none;
                border-radius: 5px;
                width: auto;
        
                &.disponible {
                    background-color: v.$color6;
                }
    
                &.no-disponible {
                    background-color: v.$color5;
                }
    
            }
        
        }
    
      
        .modal-content {
            padding: 1rem;
        
            .modal-title {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

    .cupon-activo {
        width: 100%;
        max-width: 1200px;
        margin: 2rem auto 6rem;
        padding: 3rem;
        border-radius: 10px;
        border: 1px solid v.$color5;
        box-shadow: v.$box-shadow;
        background: v.$degradadoRed;
        color: v.$blanco;
        text-align: center;

        @include m.mixMQ(v.$movil) {
            width: 90%;
        };

        i {
            font-size: 4.5rem;
            margin-bottom: 2rem;
            color: #FFC107;
        }

        p {
            color: v.$blanco;     
            font-size: 2.2rem;       

            @include m.mixMQ(v.$movil) {
                font-size: 1.8rem;
            };
    
        }

    }
    
}

.curso-nodisponible {
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background: v.$color4;

    h2 {
        color: v.$color1;
        margin: 0;
        padding-bottom: 10px;
        text-transform: uppercase;
    }

    h5 {
        color: v.$blanco;     
        margin-bottom: 2rem;   
    }

    p {
        color: v.$blanco;
        line-height: 1.5;
        margin: 10px 0;
    }    

    .highlight {
        font-weight: bold;
        color: v.$color1;
        font-size: 1.4em;
    }    
}

.curso-gratuito {
    @extend .curso-nodisponible;

    background: v.$color7; 

    h2 {
        color: v.$blanco;
    }

}
