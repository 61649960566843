@use 'base/variables' as v;
@use 'base/mixins' as m;

.datos-noticia {
    width: 90%;
    max-width: 120rem;
    margin-top: 3rem;
    padding: 3rem;

    @include m.mixMQ(v.$movil) {
        padding: 2rem;
    };

    .detalle-noticia {

        p {
            margin-top: 2rem;
            padding-bottom: 2rem;
            line-height: 2.25rem;
        }

        small {
            padding: 0;
            color: v.$color1;
        }

  
    }

}