@use 'base/variables' as v;
@use 'base/mixins' as m;


.seccion-suscribete {

    position: relative;
    width: 100%;
    color: v.$blanco;
    text-align: center;
    background: v.$color4;        

    @include m.mixFlex(flex,center,center,false,column);

    .precios-container {

        width: 90%;
        max-width: 1200px;
        height: 75%;
        padding-top: 3rem;
        text-align: center;
        margin-bottom: 3rem;

        @include m.mixMQ(v.$movil) {
            margin-bottom: 1rem;
        };

        .precios-box {
            background: v.$blanco;
            border-radius: 10px;
            margin: 0 2rem;
            box-shadow: v.$box-shadow4;
            // padding: 2rem;

            @include m.mixMQ(v.$deskXL) {
                margin: 0 1rem;
            };

            @include m.mixMQ(v.$tablet) {
                margin: 3rem 0;
            };

            .precios-box-header {
                background: v.$degradadoRed;
                height: 6rem;
                border-radius: 10px 10px 0 0;

                @include m.mixFlex(flex,center,center,0,column);

                h5 {
                    color: v.$blanco;
                    margin: 0;
                }
            }

            .precios-box-importes {

                padding: 2rem 0;
                background-color: v.$color4;
                box-shadow: v.$box-shadow;                
                
                h2 {
                    font-size: 8rem;
                    margin: 0;

                    @include m.mixMQ(v.$movil) {
                        font-size: 6rem;
                    };
                }
    
            }

            .precios-box-detalles {

                padding: 3rem 5rem;
                color: v.$blanco;

                @include m.mixMQ(v.$movil) {
                    padding: 3rem;
                };

                p {
                    font-size: 2rem;

                    @include m.mixMQ(v.$movil) {
                        font-size: 1.7rem;                        
                    };
                }

                .precios-bdkrest {
                    margin: 3rem auto;
                    width: 80%;
                }

                // .nota-redireccion {
                //     background-color: #FFF9E6; 
                //     border-left: 4px solid v.$color1;
                //     padding: 10px 15px; 
                //     margin-top: 20px; 
                //     font-size: 14px; 
                //     color: #333; 
                //     border-radius: 5px; 
                // }

            }

        }

    }

    .precios-formasPago {    
        width: 90%;
        text-align: center;
        margin: 1rem auto;
        overflow: hidden;                
        
        .fpIndicaciones {
            padding: 2rem;
            color: v.$blanco;
        
            p,a {
    
                padding: 0;
                color: v.$blanco;
                margin-bottom: .5rem;
                font-size: 1.4rem;
    
                .fa-caret-right, .fa-download {
                    font-size: 1.6rem;
                    color: v.$color6;
                }

                .fa-exclamation-triangle {
                    color: v.$color1;
                }
            }

            a:hover  {
                .fa-download {
                    color: v.$color1;
                }

            }

        }
    
    }

}
