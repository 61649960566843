@use 'base/variables' as v;
@use 'base/mixins' as m;

.datos-contacto {
    width: 90%;
    max-width: 1200px;
    margin: auto;

    iframe {
        height: 999px;
    }
}

a {

    color: #007bff; /* Color del enlace */
    text-decoration: none; /* Sin subrayado por defecto */

    &:hover {
        color: #0056b3; /* Color del enlace al pasar el ratón */
        text-decoration: underline; /* Subrayado al pasar el ratón */
    }    

}

.formulario {

    
    input, textarea {
        font-size: 1em; /* Ajusta el tamaño de la fuente aquí */
        padding: 10px;
        margin-bottom: 10px;
        // width: 100%;
        box-sizing: border-box;
    }
    
    /* Estilo adicional para el textarea */
    textarea {
        height: 150px;
        resize: vertical; /* Permite que el usuario ajuste la altura */
    }    

}