@use 'base/variables' as v;
@use 'base/mixins' as m;

.formatoHero { 
    width: 100%; 
    height: 86vh;
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,center,0,column);

    // @include m.mixMQ(v.$tablet) {
    //     height: 85vh;
    // };

    @include m.mixMQ(v.$movil) {
        height: 84vh;
    };

    .fondo_hero {
        position: absolute; 
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1; 

        opacity: 0;
        filter: brightness(0%);
        transition: all 1.25s ease-in-out;

        img {
            object-fit: cover;
            height: 100%;
            object-position: 50% 50%;
        }

    }

    .banner_visible {
        opacity: 1;
        filter: brightness(100%);
    }


    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh; // Ajusta la altura según necesites
        overflow: hidden;
        z-index: -1; 
    
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover; 
            filter: brightness(50%) contrast(120%); 
        }        
    }    

}


.clsBefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: -1; 
}

.formatoHero_Soft {

    width: 95%;
    padding: 0 3rem;

    @include m.mixMQ(v.$movil) {
        width: 98%;        
    };

    .hero-titulo {
        @include m.mixFlex(flex,center,center,wrap,column);
        text-shadow: 0px 0px 8px rgba(0,0,0,0.7);        
        margin: 0 auto;
        text-align: center;

        @include m.mixMQ(v.$movil) {
            margin-top: 4rem;
        };

        .hero-logo {
            width: 50%;

            @include m.mixMQ(v.$tablet) {
                width: 80%;
            };

            @include m.mixMQ(v.$movil600) {
                display: none;
            };
        }

        h1 {
            padding: 0;
            margin: 1rem 0;
            text-shadow: v.$box-shadow;
            line-height: 1.1;
        }
    
        h2 {
            padding: 0;
            line-height: 1.2;
            font-weight: normal;
        }

        h3 {
            line-height: 1.2;            
        }
    
        p {
            padding: 0;
            font-family: v.$fuente_sec;
            font-size: v.$titParrafo + .3rem;
            margin-top: 0; 
            color: v.$blanco;
    
            @include m.mixMQ(v.$deskXL) {
                font-size: v.$titParrafo + .2rem;            
            };
            
            @include m.mixMQ(v.$tablet) {
                font-size: v.$titParrafo - .3rem;            
            };

            @include m.mixMQ(v.$movil) {
                font-size: v.$titParrafo - .5rem; 
            };

        }
    
        a {
            width: 150px;
            text-align: center;
        }

        .barra {
            width: 15rem;
            border: 3px solid v.$color1;
            margin-bottom: .5rem;
            border-radius: 10px;
        }
    
    }

    .youtube-container {
        width: 48%;
    }

}

.heroPaginas {

    height: 70vh;    
    width: 100%;  
    color: #fff;
    overflow: hidden; 
    position: relative;
    border-bottom: 1rem solid v.$color1;
    @include m.mixFlex(flex,center,center,wrap,column);

    .hero_titulo {
        width: 85%; 
        overflow: hidden; 
        @include m.mixFlex(flex,center,0,0,column);
        color: v.$blanco;
        text-shadow: 0px 0px 8px rgba(0,0,0,0.7);          
        text-align: center;      

        h1 {
            margin-bottom: 1rem;
            text-shadow: v.$box-shadow;
            text-transform: uppercase;
            line-height: 1.1;
            
            span {
                color: v.$color1;
                font-weight: 800; 
                text-shadow: v.$box-shadow2;
            }    

        }

        h2 {
            line-height: 1.2;
        }
        
        
        p {
            font-family: v.$fuente_sec;
            color: v.$blanco;
            font-size: v.$titParrafo;
            line-height: 3.2rem;
        
            @include m.mixMQ(v.$tablet) {
                font-size: 1.5rem;
                line-height: 2rem;                
            };

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;                
            };
            
        }
    
        a {
            width: 250px;
            text-align: center;
        }

        
    }

}
