@use 'base/variables' as v;
@use 'base/mixins' as m;

.datos-patros {

    width: 90%;
    max-width: 120rem;
    margin-top: 3rem;
    padding: 3rem;

    @include m.mixMQ(v.$movil) {
        padding: 2rem;
    };

    .detalle-patros {

        h2 {
            color: v.$negro;
        }

        p {
            margin-top: 2rem;
            padding-bottom: 2rem;
            line-height: 2.25rem;
        }

        .region {
            margin: 0!important;
            padding: 0!important;
            font-style: italic;
            color: v.$color3;
            font-size: 1.8rem;

            i {
                color: v.$color1;
            }
        }

        .subtitulo {
            padding: 0;
            color: v.$color1;
        }
  
    }

}