@use 'base/variables' as v;
@use 'base/mixins' as m;

.secBlog {

    .contenedor-blog {

        width: 90%;
        margin: 4rem auto;
        padding: 20px;

        @include m.grid(4,2rem);

        @include m.mixMQ(v.$deskXL) {
            @include m.grid(3,1rem);            
        };

        @include m.mixMQ(v.$tablet) {
            @include m.grid(2,1rem);            
        };

        @include m.mixMQ(v.$movil600) {
            @include m.grid(1,1rem);            
        };

    }
 
 
}