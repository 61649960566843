@use 'base/variables' as v;
@use 'base/mixins' as m;

.secCursos {

    width: 95%;
    margin: 0 auto 5rem;
    padding: 3rem;

    @include m.mixFlex(flex,center,center,wrap,column);

    @include m.mixMQ(v.$tablet) {
        width: 90%;
        padding: 3rem 0;
    };


    .niveles-container {
        background-color: v.$blanco;
        padding: 20px;
        max-width: 1200px;
        width: 100%;
        text-align: center; 
      
      
        // Contenedor de los botones de nivel
        .niveles-botones {

            @include m.grid(4, 1fr);
            gap: 2rem;
            justify-content: center;

            @include m.mixMQ(v.$tablet) {
                @include m.grid(2, 1fr);
            };
                    
            button {
                flex: 1;
                padding: 10px 20px;
                background-color: v.$negro;
                color: v.$blanco;
                border: 1px solid v.$color1;
                border-radius: 8px;
                cursor: pointer;
                transition: background-color 0.3s ease;
        
                &:hover {
                    background-color: lighten(v.$negro, 10%);
                }
        
                &.selected {
                    background-color: v.$color1; // Rojo brillante para el botón seleccionado
                    color: v.$blanco;
                }
            }
        }
    }

    .cursos-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr); // 4 columnas para pantallas grandes
        gap: 20px;
        margin-top: 3rem;
    
        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr); // 3 columnas para pantallas medianas
        }
    
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr); // 2 columnas para pantallas pequeñas
        }
    
        @include m.mixMQ(v.$movil600) {
            grid-template-columns: 1fr;             
        };

        // Cambiamos a flexbox si hay menos de 4 elementos.
        &.few-items {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        .curso-card {

            // position: relative;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            display: flex;
            flex-direction: column;
            max-width: 450px;

            @include m.mixMQ(v.$movil) {
                margin: 1rem auto;
            };
          
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
            }
          
            // Imagen de la card
            .card-img {
                position: relative;
                width: 100%;
                height: 225px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;                    
                }

                // Estatus del curso
                .status {
                    position: absolute;
                    font-size: 1.2rem;
                    padding: 5px 10px;
                    border-radius: 0 12px 12px 0;
                    display: inline-block;
                    margin-bottom: 10px;
                    bottom: 1rem;
                    left: 0;
                    color: v.$blanco;
            
                    &.disponible {
                        background-color: v.$color6;
                    }
            
                    &.inactivo {
                        background-color: v.$color5;
                        color: v.$negro;
                    }

                    &.suscripcion {
                        background-color: v.$color1;
                    }

                    &.ebook {
                        background-color: v.$color7;
                    }

                }

                .cursoGratis {
                    position: absolute;
                    font-size: 1.2rem;
                    padding: 5px 10px;
                    border-radius: 0 12px 12px 0;
                    display: inline-block;
                    margin-bottom: 10px;
                    bottom: 5rem;
                    left: 0;
                    background-color: v.$color7;
                    color: v.$blanco;            
                }


            }
                      
            // Contenido de la card
            .card-content {
                  padding: 20px;
          
                h4 {
                    font-weight: bold;
                    font-size: 1.8rem;
                    color: v.$negro;
                }
                      
                // Descripción del curso
                p {
                    font-size: 1.6rem;
                    color: v.$color3; // Gris medio
                    margin-bottom: 15px;
                }
          
                // Botón para ver el curso
                .btn-view {
                    display: block;
                    margin: 2rem auto 0;
                    width: 150px;
                    text-align: center;
                    background-color: v.$color1;
                    color: v.$blanco;
                    border: 1px solid v.$color2;                    
                    padding: 10px 15px;
                    border-radius: 4px; 
                    cursor: pointer;
                    font-size: 1.5rem;
                    transition: all 0.4s ease-in;
                
                    &:hover {
                        // background-color: darken(v.$color1, 10%);
                        // background-color: v.$negro;

                        box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
                        border: 1px solid v.$color1;
                        color: v.$blanco;
                
                    }
                }
            }
        }
    }    
}