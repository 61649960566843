@use 'variables' as v;
@use 'mixins' as m; 

// @import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;600;700&family=Roboto:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

html { 
    font-size: 62.5%; 
    box-sizing: border-box; 
    scroll-padding-top: 0rem; 
    scroll-behavior: smooth;  
}  
  
*,    
*:before,
*:after {   
    box-sizing: inherit; 
    margin: 0;         
}  
 
body {     
    font-family: v.$fuente_principal;  
    font-size: 16px;   
    background-color: v.$blanco; 

    &::-webkit-scrollbar {    
        width: 8px; 
        height: 8px;    
    } 

    &::-webkit-scrollbar-thumb {
        background: v.$color1;     
        border-radius: 5px;   
    } 
    
}  

.body-noscroll { 
    overflow-y: hidden;
    overflow-x: hidden; 
}

p {

    font-size: clamp(1.5rem, 3vw, 1.6rem);     

    // font-size: v.$descCon;      
    color: v.$negro; 
    line-height: 1.30;    
    margin: .20rem 0; 
    font-family: v.$fuente_sec;   
    padding: 0;
} 

a {
    text-decoration: none;
}

a[href^="tel:"] {
    color: inherit; /* Hace que el color del enlace sea el mismo que el del texto que lo rodea */
    text-decoration: none; /* Elimina el subrayado del enlace */

    &:hover {
        color: v.$color1;
    }
}

a[href^="mailto:"] {
    color: inherit; /* Hace que el color del enlace sea el mismo que el del texto que lo rodea */
    text-decoration: none; /* Elimina el subrayado del enlace */

    &:hover {
        color: v.$color1;
    }
}


h1, h2, h3, h4 {
    margin: 0 0 1rem 0;
    font-family: v.$fuente_principal;
    padding: 0;
}

h1 { font-size: clamp(3rem, 7vw, 4.5rem); }

h2 { font-size: clamp(1.8rem, 5vw, 3.5rem); }

h3 { font-size: clamp(2rem, 5vw, 3rem); } 

h4 { font-size: clamp(2rem, 4vw, 2.5rem); } 

h5 { font-size: clamp(1.5rem, 3.5vw, 2.5rem); }  

img {
    max-width: 100%;  
    width: 100%; 
    height: auto;   
    display: block;
}

.iconos {  
    @include m.mixIcono(4rem, 1.7rem);
    background: v.$degradadoRed;
    color: v.$blanco;  

    transition: transform .3s ease-in;
    
    &:hover {
        transform: rotate(360deg);
    }    
}

.icono_peq {
    @extend .iconos; 

    @include m.mixIcono(2rem, 1.2rem);
    margin-right: .5rem;
}

.icono-grande {
    @extend .iconos;

    @include m.mixIcono(8rem, 3rem);
    margin-right: .5rem;
}

.iconos-blanco {
    @extend .iconos;

    background: v.$blanco;
    color: v.$color2;
}

.iconos-verde {
    @extend .iconos;

    background: v.$degradado4;
    color: v.$blanco; 
}

.seccion {
    padding: v.$sepSeccion 0;
}

.seccionGris {
    @extend .seccion;
    background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten;
}

.seccionGrisOscuro {
    @extend .seccion;
    background: v.$color4;
}

// Titulo de cada sección 
.titulo {
    margin: 5rem auto;
    text-align: center;
    width: 85%;
    color: v.$negro;

    h2 {
        text-transform: uppercase;

        span { 
            font-weight: 900;
            color: v.$color1;
            text-shadow: v.$box-shadow4;
        }
    } 

    p {
        font-size: v.$descSec;

        @include m.mixMQ(v.$tablet) {
            font-size: v.$descSec - .1rem;
        };
        @include m.mixMQ(v.$movil) {
            font-size: v.$descSec - .6rem;
        };

    }

    small {
        font-size: 2rem;
        color: v.$color1;
        font-weight: bold;
        margin: 1.5rem 0;
        display: block;
    }

}

.tituloWhite {
    @extend .titulo;

    h2, h3, h4, h5, p {
        color: v.$blanco;

        span {
            color: v.$color1;
        }
    }
}

.txtWhite {
    color: v.$blanco;
}

.boton {
    margin: 1rem;
    display: inline-block;
    font-size: v.$descCon;
    color: v.$blanco!important;
    border-radius: 50px;
    cursor: pointer;
    padding: .8rem 2rem;
    background: v.$color1;
    transition: all 0.4s ease-in;
    border: 2px solid v.$color1;

    &:hover {
        box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
        border: 2px solid v.$color1;
        color: v.$blanco;
    }
    
    &:active {
        transform: scale(0.98);
    }    

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$descCon - 0.2rem;
    };
}

.botonVerde {
    @extend .boton; 
    background: v.$color6;
    border: 2px solid v.$color6;

    &:hover {   
        color: v.$color6;
        border: 2px solid v.$color6;
    }
}

.botonGris {    
    @extend .boton;
    background: v.$color3; 
    border: 2px solid v.$color3;

    &:hover {
        color: v.$blanco;
        border: 2px solid v.$color1;
    }
}

.botonDark {    
    @extend .boton;
    background: v.$color4; 
    border: 2px solid v.$color3;

    &:hover {
        box-shadow: inset -10em 0 0 0 v.$color1, inset 10em 0 0 0 v.$color1;        
        color: v.$blanco;
    }
}

// Clase del botón que moverse al inicio de la pantalla
.botInicio {
    position: fixed;
    left: 150px;
    bottom: 32px;
    z-index: 10000; 
}


.bannerPub {
    padding: 0;
    width: 80%; 
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;

}

.tit-enorme {    
    font-size: 100px;

    @include m.mixMQ(v.$desk) {
        font-size: 80px;        
    };

    @include m.mixMQ(v.$tablet) {
        font-size: 60px;
    };
    @include m.mixMQ(v.$movilSM) {
        font-size: 45px;
    };
}

.font-bold {
    font-weight: bold;
}

.text-derecha {
    text-align: right;
}

.text-izq {
    text-align: left;
}

.anim-mouse {
    width: 100%;
    position: absolute;
    bottom: 6rem;
    display: flex;
    justify-content: center;
    padding-right: 3rem;

    .mouse {
        @include m.bgGradient;
        position: relative;
        width: v.$widthMouse;
        height: v.$heightMouse;
        border-radius: 100px;
        background-size: 100% 200%;
        animation: 
        colorSlide v.$animDuration linear infinite,
        nudgeMouse v.$animDuration ease-out infinite;

        @include m.mixMQ(v.$tablet) {
            width: v.$widthMouseSM;
            height: v.$heightMouseSM;                
        };
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
        &:before {
            width: v.$widthMouse - v.$borderMouse;
            height: v.$heightMouse - v.$borderMouse;
            background-color: v.$colorBg;
            border-radius: 100px;

            @include m.mixMQ(v.$tablet) {
                width: v.$widthMouseSM - v.$borderMouseSM;
                height: v.$heightMouseSM - v.$borderMouseSM;                    
            };
        }
        &:after {
            background-color: v.$colorOutline;
            width: v.$sizeTrackball; 
            height: v.$sizeTrackball;
            border-radius: 100%;
            animation: trackBallSlide v.$animDuration linear infinite;
        }
    }
}

.mouse-b2 {
    bottom: 2rem;
}


// ! SECCIÓN DE PREGUNTAS FRECUENTES
.genFaqs {
    // padding-top: 6rem;
    padding-bottom: 1rem;

    .disFaqs_container { 
        margin: 2rem auto;
        padding: 2rem;

        @include m.mixMQ(v.$movilSM) {
            padding: 2rem 0;
        };
    }

    .accordion {

        @include m.mixMQ(v.$movilSM) {
            width: 90%;
            margin: 0 auto;

        };

        button {
            position: relative;
            display: block;
            text-align: left;
            width: 100%;
            padding: 2em 0;
            font-size: 1.5rem;
            font-weight: 400;
            border: none;
            background: none;
            outline: none;
            font-family: v.$fuente_principal;
            color: v.$negro;

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;
            };

            &:hover,
            &:focus {
                cursor: pointer;
                outline: none;
                background: v.$color_light;
                border: none;

            }

            .accordion-title {
                padding: 1em 1.5em 1em 0;
            }

            .iconFaq {
                display: inline-block;
                position: absolute;
                top: 18px;
                right: 0;
                width: 22px;
                height: 22px;
                border: 1px solid;
                border-radius: 22px;
                color: v.$color3;

                &::before {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 9px;
                    left: 5px;
                    width: 10px;
                    height: 2px;
                    background: currentColor;
                }

                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    top: 5px;
                    left: 9px;
                    width: 2px;
                    height: 10px;
                    background: currentColor;
                }
            }
        }

        button[aria-expanded='true'] {
            // color: v.$color1;
            border: none !important;

            .iconFaq {
                &::after {
                    width: 0;
                }
            }

            +.accordion-content {
                opacity: 1;
                max-height: 9em;
                transition: all 200ms linear;
                will-change: opacity, max-height;

                @include m.mixMQ(v.$movilSM) {
                    max-height: 12em;
                };
            }
        }

        .accordion-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 200ms linear, max-height 200ms linear;
            will-change: opacity, max-height;

            p {
                font-size: 1.5rem;
                font-weight: 300;
                margin: 2em 0;

                @include m.mixMQ(v.$movil) {
                    font-size: 1.2rem;
                };
            }
        }
    }
}

.color_blanco {
    color: v.$blanco!important;
}

.glbLista_elementos {
    width: 100%;
    height: auto;
    margin: 1.5rem 0;
    z-index: 500;
    overflow: hidden;     

    .lista_elemento {
        @include m.mixFlex(flex,center,center,nowrap,0);
        margin: 5px 0;
        width: auto;

        i {
            font-size: 1.5rem;
            margin-top: 2px;
            margin-right: .8rem;
        }
    
        .color_ver {
            color: v.$color6;
        }
        
        p {
            padding: 0;
            margin: 0;
            line-height: 1.20;
            font-size: 2rem!important;
            text-align: left!important;
            width: 100%;

            @include m.mixMQ(v.$movil414) {
                font-size: 1.7rem!important;                
            };
        }

        a {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &:link {
                color: v.$blanco;
            }
              
            &:visited {
                color: #0a07b5;
            }            
        }

        .destacar {
            font-weight: bolder;
            margin-bottom: 1rem;
            color: #FFD700;
        }

    }

    small {
        display: block;
        margin-top: 2rem;
        color: v.$blanco;
    }

}

.color_rojo {
    color: v.$color1;
}

.glbListaWhite {

    @extend .glbLista_elementos;

    p {
        color: v.$blanco;
    }
}

.glbLista_elementos-2 {

    @extend .glbLista_elementos;

    width: 50%;
    margin: 3rem auto;

    @include m.mixMQ(v.$desk) {
        width: 90%;
    };
}


.filtro-negro { 
    position: absolute;
    width: 100%;
    height: 100%; 
    z-index: -5;
    top: 0;
    left: 0;

    &::before {
        position: absolute;
        top: 0;
        left: 0; 
        width: 100%;
        height: 100%;
    
        content: "";
        background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.75) 100%);
    }

    img {
        object-fit: cover;
        height: 100%;
    }
}

.filtro-gris {
    @extend .filtro-negro;

    img {
        filter: grayscale(100%) brightness(50%);
        transition: filter 0.3s ease; /* Para una transición suave */
    }    
}

.filtro-sinfondo {
    @extend .filtro-negro;

    &::before {
        background: none;        
    }    
}

.filtro-negro-border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px 10px 0 0;
    }    
}


.filtro-negro-deg2 {
    @extend .filtro-negro;

    &::before {
        background-image: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%);
    }    
}

.filtro-negro-deg2border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}



.cls_pub {
    max-height: 65vh;
    overflow: hidden;
}

.pub_content {
    
    .pub-logo {
        width: 35%;
        margin-bottom: 3rem;

        @include m.mixMQ(v.$movil) {
            margin-bottom: 1rem;
        };

        @include m.mixMQ(v.$movilSM) {
            display: none;
        };
        
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .pub-textos {
        // width: 80%;

        h3 {
            font-size: 3rem;

            @include m.mixMQ(v.$desk) {
                font-size: 2.75rem;
            };
            @include m.mixMQ(v.$tablet) {
                font-size: 2rem;
            };
            @include m.mixMQ(v.$movil) {
                font-size: 1.5rem;
                margin-bottom: 3px; 
            };  
 
        }

        p {  
            font-size: 2.5rem;   
  
            @include m.mixMQ(v.$desk) {
                font-size: 2rem;
            };
            @include m.mixMQ(v.$tablet) { 
                font-size: 1.75rem;  
            };  
            @include m.mixMQ(v.$movil) {
                font-size: 1.25rem;
            }; 
        } 

        button {
            @include m.mixMQ(v.$movil) {
                margin-top: 1rem!important;  
                font-size: 1.25rem!important; 
                padding: 5px 10px; 
            };
        }
    } 
}  

.sec_videos {    
    width: 55%;  
    margin: 3rem auto 6rem;
    @include m.mixFlex(flex,center,center,wrap,column);

    @include m.mixMQ(v.$deskXL) {
        width: 70%;
    };

    @include m.mixMQ(v.$tablet) {
        width: 90%;
        margin-bottom: 3rem;
    }; 
}

.youtube-container {
    position: relative;
    width: 100%;  
    height: auto!important;
    aspect-ratio: 16/9;
    margin: 1rem auto;
    padding: 1rem; 

    .youtube-video {  
        width: 100%;  
        height: auto; 
        aspect-ratio: 16/9;                    
        background-size: cover; 

        iframe {
            width: 100%; 
            height: auto;
            aspect-ratio: 16/9;
            border-radius: 10px; 
            box-shadow: v.$box-shadow;  
        }    
    }        

    .youtube-portada {
        position: absolute;
        top: 0;
        left: 0; 
        width: 100%;  
        height: auto; 
        background-size: cover;
        padding: 1rem;

        img { 
            width: 100%; 
            border-radius: 10px; 
            object-fit: cover;
            box-shadow: v.$box-shadow;
        }    
    }        
}    

.play {
    position: absolute;
    cursor: pointer;
    width: 70px!important;
    height: 70px!important;
    display: block;  
    transition: .5s;
    border-radius: 50%;

    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%);

    -webkit-animation: ripple 2.5s linear 1s infinite;
    animation: ripple 2.5s linear 1s infinite;

    img {
        width: 100%;
        transition: width 0.5s;
    }
    
    &:hover {
        background: v.$color1;
    }            
}

.glbBanda-color {
    position: relative;
    width: 100%;
    background: v.$degradadoRed;
    padding: 3rem 2rem;    

    @include m.mixFlex(flex,center,center,nowrap,column);

    // Este DIV lo utilizo para mostrar diferentes textos en la misma barra
    // Cada texto solo dura algunos segundos

    .banda_content {
        width: 90%;
        max-width: 1200px;
        color: v.$blanco;
        text-align: center;
        height: 5vh;
        @include m.mixFlex(flex,center,center,nowrap,column);
        margin: 2rem auto;
    
        p {
            color: v.$blanco;
        }    
    }

    // Este DIV lo utilizo para mostrar un texto fijo dentro de la barra

    .banda-texto-normal {
        
        width: 90%;
        max-width: 1200px;
        color: v.$blanco;
        @include m.mixFlex(flex,center,center,nowrap,column);
        padding: 5rem 0;
        text-align: center;

        h4 {
            margin-top: 2rem;
        }

        .banda-box {
            width: 100%;
            margin: 3rem 0;

            @include m.grid(5,2);
            align-items: start;

            .banda-point {
                padding: 1rem;
                @include m.mixFlex(flex,center,center,fxWr,column);

                .banda-icono {
                    width: 10rem;
                    height: 10rem;
                    background: v.$blanco;
                    border-radius: 50%;
                    box-shadow: v.$box-shadow4;
                    margin-bottom: 1rem;

                    a {
                        width: 100%;
                        height: 100%;
                        @include m.mixFlex(flex,center,center,fxWr,fxFd);

                        i {
                            margin: auto;
                            color: v.$color1;
                            font-size: 3rem;
                        }
    
                    }


                    &:hover {
                        background: v.$color4;
                        

                        i {
                            color: v.$blanco;
                        }
                    }
                }

                h6 {
                    padding: .5rem;
                    text-transform: uppercase;
                }

                p {
                    color: v.$blanco;
                }
            }
        }

    }

}

.glbBanda-color2 {
    @extend .glbBanda-color;

    @include m.mixMQ(v.$tablet) {
        height: 20vh;
    };
}

.efecto-vidrio {

    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.75);
    border-left: 1px solid rgba(255, 255, 255, 0.75);
    background: rgba(0,0,0, 0.50);
    border-radius: 10px;

}

.efecto-fondo-fijo {

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

    }

}

.glbMensaje-navega {
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translateX(-50%);

    @include m.mixFlex(flex,center,center,nowrap,column);

    h4 {
        @include m.mixMQ(v.$movilSM) {
            font-size: 1.3rem;
        };
    }

    i {
        font-size: 3rem;
        animation: moveUpDown 2s infinite ease-in-out;            
        color: v.$color1;
    }
}

.call-to-action {
    margin: 4rem auto;
    text-align: center;
}

// ! SECCIÓN PUBLICIDAD
// ! =================================================================

.secBannerPub { 
    max-width: 1200px;
    margin: 3rem auto 6rem;
    padding-top: 3rem;

    @include m.mixMQ(v.$deskXL) {
        width: 90%;
    };

    @include m.mixMQ(v.$movil) {
        width: 95%;
    };
}

.fto-img-pub {
    height: 100%; 
    object-fit: cover;
    border-radius: 10px;
    // filter: brightness(0.5);    

}

// ! SECCIÓN BARRA DE COLOR PARA VISUALIZAR MENSAJES INFORMATIVOS
// ! =================================================================

#message-container {
    font-size: clamp(2rem, 3vw, 4.5rem);     
    transition: opacity 1s ease;
    opacity: 0;
}

#message-title {
    font-weight: bold;
}


// ! CARS DE LAS CARDS INFORMATIVAS DEL BLOG, UTILIZADAS EN EL SLIDE 
// ! DE LA PÁGINA PRINCIPAL Y DE LA PÁGINA DEL BLOG
// ! =================================================================

.glb-blog-card {
    @include m.mixFlex(flex,center,0,0,column);
    border-radius: 1rem;
    box-shadow: v.$box-shadow;
    max-width: 540px;
    height: 500px;
    padding: 0;
    margin: 2rem 1rem;

    @include m.mixMQ(v.$movil600) {
        margin: 2rem auto;
    };

    .box_image {  
        width: 100%;   
        height: 40%;
        overflow: hidden;
        position: relative; 
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem; 
            transition: all .5s ease-in-out;
        }
    }            

    .box_content {
        padding: 2rem;
        padding-bottom: 0;
        @include m.mixFlex(flex,0,0,0,column);
        height: 60%;

        .box_texto {
            height: 80%;
            overflow-y: auto;
            margin: 0;

            a {

                h5 {
                    transition: all 0.5s ease-in-out;
                    margin: 0;
                    font-size: 2rem;                    

                    &:hover {
                        color: v.$color1;
                    }

                    @include m.mixMQ(v.$deskXL) {
                        font-size: 1.8rem;
                    };

                    &:hover {
                        color: v.$color1;
                    }

                }
            }

            small {
                padding: 0;
                color: v.$color1;
            }

            // DESCRIPCIÓN DEL PRODUCTO
            p {
                margin-top: 1rem;
                font-size: 1.6rem;
                color: v.$color4;
            }
        }

        .box_footer {
            @include m.mixFlex(flex,space-between,center,0,0);
            height: 20%;
            border-top: v.$borde;
            padding: 5px;

            a {
                color: v.$negro;

                &:hover {
                    color: v.$color1;
                }
            }
    
            // .box_footer
            @include m.mixMQ(v.$movil) {
                border-top: none;
            };


        }
        
    }   

    &:hover .box_image img {

        transform: scale(1.05);
        -webkit-transform: scale(1.05);

        filter: saturate(180%);
    }
    
}             

.dark-mode {
    background-color: v.$color4;
    color: #fff; /* Color de texto claro */

    p, .modal-title p {
        color: v.$blanco;
    }

    .modal-header {
        button {
            color: v.$blanco!important;
        }
    }

}

.no-mostrar {
    display: none!important;
}

.textoGrande {
    font-size: 6rem;
}

.glbAudio-Card {
    margin: 2rem auto;
}

.glbProximamente {

    width: 90%; 
    max-width: 1200px;
    margin: 2.5rem auto;
    padding: 2.5rem;
    background: rgba(255, 255, 255, 0.2); /* Fondo semi-transparente */
    border-radius: 10px; 
    backdrop-filter: blur(10px); /* Aplicamos el efecto de blur al fondo detrás del div */
    -webkit-backdrop-filter: blur(10px); /* Compatibilidad con navegadores WebKit */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Borde tenue para resaltar el div */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Un poco de sombra para el efecto de profundidad */

    @include m.mixMQ(v.$movil) {
        width: 100%;
    };

    h2 {
        font-size: clamp(2rem, 5vw, 4.5rem)!important;             
    }

    h4 {
        color: v.$color1;        
    }

    h5 {
        line-height: 1.2;
    }
    

}

.glb-container2col {

    width: 90%;
    max-width: 1500px;
    margin: 0 auto 5rem;
    @include m.mixFlex(flex,space-between,stretch,nowrap,row);

    @include m.mixMQ(v.$tablet) {
        width: 90%;
        flex-direction: column;        
    };

    .glbColumna {
        color: v.$blanco;
        text-align: center;
        margin: 0 1.5rem;
        padding: 2rem;
        flex: 1;

        @include m.mixMQ(v.$tablet) {
            margin: 2rem 0;
        };
        
        p {
            color: v.$blanco;

            @include m.mixMQ(v.$tablet) {
                font-size: 2rem;
            };
        }

        h3 {
            margin-top: 2rem;
            margin-bottom: 0;
        }

        .precio-destacado {
            font-size: 5rem;

            @include m.mixMQ(v.$movil) {
                font-size: 3.5rem;
            };
        }

        .destacado {

            @include m.mixFlex(flex,center,center,fxWr,fxFd);

            i {
                margin-left: 1rem;
                color: #FFD700;
                font-size: 2rem;
            }

        }

        .subtitulo {
            font-size: 1.8rem;
            border-bottom: 2px solid v.$color1;
            margin-bottom: 3rem;
            padding-bottom: 1rem;
        }

        .logo-columna {
            width: 50%;
            margin: 0 auto 3rem;

            @include m.mixMQ(v.$desk1024) {
                width: 65%;
            };

            img {
                width: 100%;
                object-fit: cover;
                filter: drop-shadow( 0 0 10px rgba(255, 255, 255, .8))                
            }

        }

        .logo-columna2 {
            @extend .logo-columna;
            width: 25%;

            @include m.mixMQ(v.$desk) {
                width: 35%;
            };

            @include m.mixMQ(v.$tablet) {
                width: 50%;
            };

            @include m.mixMQ(v.$movil) {
                width: 65%;
            };

        }

        .glbAhorro {
            width: 95%;
            text-align: left;
            margin: 2rem auto;
            padding: 2rem;
            background-color: #FFF9E6;
            color: v.$negro;
            border-radius: 10px;

            p {
                color: v.$negro;
            }
        }

    }

    .glb-barra {            
        height: auto;
        border: 1px solid v.$color3;

        @include m.mixMQ(v.$movil) {
            width: 50%;
            height: auto;
            margin: 3rem auto;
        };
    }
}

.gblComunidad-promocion {
    margin: 2.5rem 2rem;
    padding: 2rem 1rem;
    // background-color: #C9CCD3;
    background: v.$degradadoRed;
    border-radius: 7px;
    // color: v.$negro;

    h6 {
        font-size: 2rem!important; 
    }

    h3 {
        margin: 0;
        color: #FFD700;
    }

    p {
        color: v.$blanco!important;

        @include m.mixMQ(v.$movil) {
            font-size: 1.6rem!important;
        };
    }
}

.glbContainer-cards {
    margin: 0 auto 3rem;
    width: 95%;
    max-width: 1200px;
    color: v.$blanco;
    padding: 3rem;

    @include m.grid(3,1);

    @include m.mixMQ(v.$tablet) {
        @include m.grid(2,1);        
    };

    @include m.mixMQ(v.$movil) {
        @include m.grid(1,1rem);        
    };


    .glbCard {
        margin: 1rem;
        padding: 2rem;
        background: v.$degradadoRed;

        i {
            font-size: 3.5rem;
            margin: 1rem auto;
        }

        p {
            color: v.$blanco;

            @include m.mixMQ(v.$movil) {
                font-size: 1.6rem;
            };
        }

    }
}

.glbContainer-cards-2 {
    @extend .glbContainer-cards;

    @include m.mixMQ(v.$tablet) {
        @include m.grid(1,1);        
    };

}

.glbGrayscale img {
    filter: grayscale(100%);
    transition: filter 0.3s ease; /* Para una transición suave */
}

.glbPromocionBDKREST { 

    width: 90%;
    max-width: 1200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);        
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    overflow: hidden;       
    
    margin: 6rem auto;


    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
    };

    .image-container {
        flex: 1 1 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: v.$degradadoRed;

        @include m.mixMQ(v.$tablet) {
            padding: 5rem;
        };


        img {
            max-width: 60%;
            height: auto;        
            filter: drop-shadow( 0 0 10px rgba(0, 0, 0, .8)); 

        }
    }        

    .content {
        flex: 1 1 50%;
        padding: 20px; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: v.$color4;
        color: v.$blanco;

        h2 {
             color: v.$color1;
            margin: 0;
            padding-bottom: 10px;
            line-height: auto;
        }

        p {
            font-size: 1.2em;
            line-height: 1.3;
            margin: 10px 0;
            color: v.$blanco;            

            @include m.mixMQ(v.$movil) {
                font-size: 1em;
                line-height: 1.2;                    
            };
        }

        .highlight {
            font-weight: bold;
            color: v.$color1;
            font-size: 1.3em;
        }    

        .bdkrest-link {
            display: inline-block;
            margin-top: 10px;
            font-size: 1em;
            color: v.$blanco;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
                color: #a82424; 
            }                
        }

        .apunte {
            font-size: 1.5rem;
            font-style: italic;

            i {
                color: #a82424; 
            }                

        }

        .instructions {
            font-weight: bold;

            i {
                color: v.$color1;
                margin-right: .5rem;
            }

        }

        .contact-options {

            padding: 2rem;
            background-color: v.$color3;
            border-radius: 7px;
            margin-top: 1rem;

            i {
                color: v.$color1;
                margin-right: .5rem;
            }
        }

    }        

    .logo-container {
        margin: 2rem 0 .5rem;
        text-align: center;

        img {
            max-width: 250px;
            height: auto;
        }        

    }
}

.glbCurso-mockup {

    margin: 3rem auto 5rem;
    width: 50%;

    @include m.mixMQ(v.$movil600) {
        width: 75%;
    };

    img {
        object-fit: cover;            
    }

}

.glbNotas {
    background-color: #FFF9E6; 
    border-left: 4px solid v.$color1;
    padding: 2rem; 
    margin-top: 20px; 
    font-size: 14px; 
    color: #333; 
    border-radius: 10px; 
    text-align: left!important;

    @include m.mixMQ(v.$tablet) {
        p {
            font-size: 1.7rem!important;
        }
    };
}
