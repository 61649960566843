@use 'base/variables' as v;
@use 'base/mixins' as m;




.secNiveles { 

    position: relative;
    width: 95%;
    height: auto;
    margin: 0 auto;

    @include m.mixMQ(v.$tablet) {
        width: 90%;
    };

    .niveles-container {

        margin-bottom: 4rem;
        @include m.grid(3,2);

        @include m.mixMQ(v.$tablet) {
            @include m.grid(1,1rem);            
            margin: 2rem auto;
        };
    
        .niveles-card {

            background: v.$degradadoRed;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: v.$box-shadow;
            margin-right: 2rem;

            @include m.mixMQ(v.$tablet) {
                margin: 1rem auto;
                width: 100%;
            };

            .card-titulo {
                background-color: v.$color4;
                color: v.$blanco;
                text-align: center;
                padding: 1.5rem;

                h4 {
                    margin: 0;

                    @include m.mixMQ(v.$desk) {
                        font-size: 2rem;
                    };
                }
            }

            .card-header {
                position: relative;

                .card-img {

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .filtro {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.75) 100%);                    
                }    

                .niv-card-text {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    color: v.$blanco;
                    z-index: 100;
                    text-align: center;
                    padding: 2rem;

                    p {
                        color: v.$blanco;
                        font-size: 1.8rem;

                        @include m.mixMQ(v.$deskXL) {
                            font-size: 1.5rem;
                        };

                        @include m.mixMQ(v.$tablet) {
                            font-size: 2.5rem;
                        };

                        @include m.mixMQ(v.$movil) {
                            font-size: 1.6rem;
                        };

                    }

                }
            }

            .card-beneficios {
                padding: 2rem;
                color: v.$blanco;

                p {
                    color: v.$blanco;
                }
            }
        }        
    }    

}
