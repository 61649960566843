@use 'base/variables' as v;
@use 'base/mixins' as m;

.secPatrocinador {

    width: 100%;
    position: relative;
    color: white;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/restauni-bkg6.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

    }


    .patrocinador-promocion {
        width: 90%;
        max-width: 1200px;
        margin: 3rem auto;
        border-radius: 10px;
        position: relative;
        overflow: visible; 
        // box-shadow: 0 0 15px rgba(0, 150, 255, 0.6); // Primer resplandor fijo
        animation: blink-shadow 1.5s infinite;    

        img {
          border-radius: 10px;
          width: 100%;
          object-fit: cover;
          display: block;
          position: relative;
          z-index: 2;
        }
    }       
}

.seccion-invita-patrocinador {
    position: relative;
    width: 100%;
    background: v.$color4; 
    color: v.$blanco;    
    text-align: center; 

    .invitacion {

        width: 85%;
        margin: 0 auto 3rem;
        text-align: center;

        @include m.mixMQ(v.$deskXL) {
            width: 95%;
        };
        @include m.mixMQ(v.$tablet) {
            width: 90%;
        };

        p {
            color: v.$blanco;
        }

        .invitacion-points {
            width: 50%;
            margin: 2rem auto;

            .glbLista_elementos {

                .lista_elemento {

                    p {
                        width: auto !important;
                    }
                }
                
            }
            
        }

    }

}

.contenedor-blog {
    @include m.mixMQ(v.$movil) {
        padding: 0!important;
    };
}