@use 'base/variables' as v;
@use 'base/mixins' as m;

// ! Seccón sobre los diferentes Beneficios que obtienen con nosotros
.secCaracteristicas {

    position: relative;
    padding-bottom: 4rem;

    .glbContainer-cards {
        margin-bottom: 6rem!important;
    }
    
}
