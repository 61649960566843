@use 'base/variables' as v;
@use 'base/mixins' as m;


.seccion-problema { 

    width: 100%;
    height: auto;
    position: relative;
    @include m.mixFlex(flex,center,center,false,false);

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/mesero_estresado.jpg');

    }

    .problema-container {

        width: 95%;
        max-width: 1200px;
        margin: auto;
        text-align: center;
        color: v.$blanco;

    }
}
