@use 'base/variables' as v;
@use 'base/mixins' as m;

.seccion-beneficio-bdkrest {

    width: 100%;
    position: relative;
    @include m.mixFlex(flex,center,center,false,column);

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.75) 100%), url('../img/fondo-bdkrest.jpg');
    }

    padding-bottom: 4rem;

    .glb-container2col {
        .glbColumna {
            padding: 0!important;
        }
    }
}

