@use 'variables'as v;
@use 'base/mixins'as m;
@use "sass:math";

// Esta animación se utiliza en la animcación de la página principal
// La que simula el mouse

@keyframes colorSlide {
    0% {
        background-position: 0% 100%;
    }

    20% {
        background-position: 0% 0%;
    }

    21% {
        background-color: v.$colorOutFade;
    }

    29.99% {
        background-color: v.$colorOutline;
        background-position: 0% 0%;
    }

    30% {
        background-color: v.$colorOutFade;
        background-position: 0% 100%;
    }

    50% {
        background-position: 0% 0%;
    }

    51% {
        background-color: v.$colorOutFade;
    }

    59% {
        background-color: v.$colorOutline;
        background-position: 0% 0%;
    }

    60% {
        background-color: v.$colorOutFade;
        background-position: 0% 100%;
    }

    80% {
        background-position: 0% 0%;
    }

    81% {
        background-color: v.$colorOutFade;
    }

    90%,
    100% {
        background-color: v.$colorOutline;
    }
}

// Esta animación simula el movimiento de la ruedita del moouse
// de la página principal

@keyframes trackBallSlide {
    0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }

    6% {
    opacity: 1;
    //   transform: scale(0.9) translateY(v.$posTrackball/4);
    transform: scale(0.9) translateY(math.div(v.$posTrackball, 4));
    }

    14% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(v.$posTrackball*2);
    }

    15%,
    19% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(-20px);
    }

    28%,
    29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }

    30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }

    36% {
    opacity: 1;
    //   transform: scale(0.9) translateY(v.$posTrackball/4);
    transform: scale(0.9) translateY(math.div(v.$posTrackball, 4));
    }

    44% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(v.$posTrackball*2);
    }

    45%,
    49% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(-20px);
    }

    58%,
    59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }

    60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }

    66% {
    opacity: 1;
    //   transform: scale(0.9) translateY(v.$posTrackball / 4);
    transform: scale(0.9) translateY(math.div(v.$posTrackball, 4));
    }

    74% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(v.$posTrackball*2);
    }

    75%,
    79% {
    opacity: 0;
    transform: scale(v.$shrinkTrackball) translateY(-20px);
    }

    88%,
    100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
    }
}

// Esta animación también es parte de las animaciones
// del mouse de la página principal

@keyframes nudgeMouse {
    0% {
    transform: translateY(0);
    }

    20% {
    transform: translateY(v.$posMouse);
    }

    30% {
    transform: translateY(0);
    }

    50% {
    transform: translateY(v.$posMouse);
    }

    60% {
    transform: translateY(0);
    }

    80% {
    transform: translateY(v.$posMouse);
    }

    90% {
    transform: translateY(0);
    }
}

// Esta animación realiza el efecto que hacer una camara cuando está grabando

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}


@keyframes pulsing {
  to {
      box-shadow: 0 0 0 3rem #0000;
  }
}

// Esta animación se utiliza en los botones "PLAY" para reproducir un vídeo
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 .7rem rgba(255,255,255, 0.2),
                0 0 0 1.5rem rgba(255,255,255, 0.2),
                0 0 0 5rem rgba(255,255,255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 1.5rem rgba(255,255,255, 0.2),
                0 0 0 4rem rgba(255,255,255, 0.2),
                0 0 0 8rem rgba(255,255,255, 0);
  }
}


@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

// Esta animacion la utilizo para mover un icono de derecha a izquierda
// Para indicarle al usuario que puede mover el mouse para ver más contenido

@keyframes dragico {
    0% {
      transform: translateX(45%);
    }
    100% {
      transform: translateX(-45%);
    }
}


/* Animación para mover el elemento de arriba hacia abajo */
// Lo utilizo en el pie de cada sección, para indicarle al usuario que se siga moviendo hacia abajo
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}

// Animación que aplico a la sombra de la sección del banner de la promoción en la sección de precios.

@keyframes blink-shadow {
  0%, 100% {    
      box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5); /* Estado inicial y final con sombra visible */
  }
  50% {
      box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1); /* Estado intermedio con sombra menos visible */
  }
}
