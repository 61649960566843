@use 'base/variables' as v;
@use 'base/mixins' as m;

.secDespedida {
    width: 100%; 
    position: relative;
    // overflow: hidden;

    .titulo {
        position: absolute;
        top: 5%;
        left: 55%;
        width: 60rem;        

        @include m.mixMQ(v.$deskXXL) {
            top: 0;
            left: 52%;
        };

        @include m.mixMQ(v.$deskXL) {
            top: -10%;
            left: 50%;
            width: 50rem;
        };

        @include m.mixMQ(v.$desk1024) {
            left: 45%;
        };

        @include m.mixMQ(v.$desk) {
            left: 42%;
        };

        @include m.mixMQ(v.$tablet) {
            position: relative;
            top: 0;
            left: 0;
            width: 92%;
            margin: 5rem auto 0; 
        };

        h2 { font-size: clamp(1.8rem, 3vw, 4.5rem)!important; }        

        p {

            @include m.mixMQ(v.$tablet) {
                font-size: 1.5rem;
            };

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;                
            };
        }

        .despedida_contacto {
            margin-top: 2rem;

            a {
                width: 20rem;
                margin: 1rem;

                @include m.mixMQ(v.$tablet) {
                    width: 15rem;
                };
            }
        }
    }
}
