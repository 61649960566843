@use 'base/variables' as v;
@use 'base/mixins' as m;


.seccion-modalidades {

    width: 100%;
    position: relative;
    color: v.$blanco;
    text-align: center;
    background: v.$color4;        

    .modal-container {

        width: 85%;
        margin: auto;
        text-align: center;

        @include m.mixMQ(v.$deskXL) {
            width: 90%;
        };

        .modal-points {
            margin: 5rem; 

            @include m.grid(3,3rem);

            @include m.mixMQ(v.$tablet) {
                @include m.grid(1,5rem);
                width: 100%;
                margin: 0 auto;                
            };

            .modal-point {

                padding: 3rem 2rem;
                box-shadow: v.$box-shadow4;

                @include m.mixMQ(v.$movil414) {
                    @include m.mixFlex(flex,left,center,wrap,row);
                    // padding: 3rem 2rem 2rem;
                };
    
                .icono-modalidad {
                    font-size: 5rem;
                    color: v.$color1;
                    margin-bottom: 1rem;

                    @include m.mixMQ(v.$movil414) {
                        width: 15%; 
                        font-size: 3rem;
                        margin-right: 1rem;
                    };

                }

                h6 {
                    font-size: 1.8rem;
                    text-transform: uppercase;
                }

                p {
                    padding: 0;
                    color: v.$blanco;
                    font-size: 1.6rem;

                    @include m.mixMQ(v.$movilSM) {
                        // width: 75%;
                        font-size: 1.5rem;
                    };

                }

                a {
                    margin: auto !important;
                }

                .modalidad-beneficios {
                    padding: 1rem;
                }
            }

        }    

        .statistics {
            text-align: center;
            color: v.$blanco;
            margin-top: 2rem;
            font-weight: bolder;
        }    

        .rhetorical-questions {
            font-style: italic;
            text-align: center;
            margin-top: 20px;
            color: v.$blanco;
            font-size: 2.2rem;
        }    

    }

}
