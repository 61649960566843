
// Software

$idSoftware: 2;

// Tipografia
$fuente_principal: 'Rubik', sans-serif;
$fuente_sec: 'Source Sans Pro', sans-serif;
$titHero: 4.5rem;
$titSec: 3.5rem; 
$titulos: 3rem;   
$titParrafo: 2.3rem;
$titH5: 2rem;  
$descSec: 2.5rem;    
$descCon: 1.6rem; 
 

// Colores  
$negro: #000000;   
$blanco: #FFFFFF;    
$color1: #ee0014;   
$color2: #8d0012;    
$color3: #7d7d7d;     
$color4: #1F2022;     
$color5: #d1d1d1;     
$color6: #00BB2D;  
$color7: #0000FF;     
$color_light: #F5F5F5; 

$degradadoRed: linear-gradient(0deg, #8d0012 35%, #ee0014 100%);
$degradado2: linear-gradient(180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
$degradado3: linear-gradient(180deg, rgba(220,220,220,1) 0%, rgba(0,0,0,1) 100%);
$degradado4: linear-gradient(0deg, rgba(3,106,28,1) 12%, rgba(0,187,45,1) 62%);

// Tamaños de Media Queries 
$movil360: 360px;  
$movilSM: 375px;  
$movil414: 414px;
$movil: 480px;
$movil600: 600px;
$tablet: 768px; 
$desk: 992px; 
$desk1024: 1024px;
$deskXL: 1200px;    
$deskXXL: 1400px; 
$deskSuper: 1900px;

// Bordes
$box-shadow: .5rem .5rem 1.5rem rgba(0, 0, 0, .3);
$box-shadow2: 2px 5px 10px rgba(0, 0, 0, 0.5);
$box-shadow3: 5px 20px 50px rgba(0, 0, 0, 1); 
$box-shadow4: .5px 2px 3px rgba(255, 255, 255, 0.7);
$box-shadow5: 0px 4px 8px 2px rgba(255, 255, 255, 0.2);

$borde: 1px solid rgb(208, 208, 208);
$borde2: 1px solid #d1d1d1;  
$borde3: 1px solid #707070;  
$borde4: 1px solid rgba(0, 0, 0, .1); 
 
// Otros 

$sepSeccion: 6rem;
$animationSpeed: 25s;  

// VARIABLES PARA LA ANIMACIÓN DEL MOUSE

$colorBg: #222a30;
$colorOutline: #ffffff;
$colorOutFade: #4e5559;

$widthMouse: 21px;
$heightMouse: 44px; 
$borderMouse: 3px;

$widthMouseSM: 15px;
$heightMouseSM: 38px; 
$borderMouseSM: 2px;

$posMouse: 4px;
$posText: 2px;

$sizeTrackball: 5px; 
$posTrackball: 10px;
$shrinkTrackball: 0.4;

$animDuration: 5s;
