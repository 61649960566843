@use 'base/variables' as v;
@use 'base/mixins' as m;

.secComunidad {

    width: 100%;
    position: relative;
    color: white;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/restauni-comunidad.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

    }

    .glb-logo {
        width: 30%;
        margin: 5rem auto 0;

        @include m.mixMQ(v.$tablet) {
            width: 50%;
            margin-bottom: 3rem;
        };

        img {
            width: 100%;
            object-fit: contain;
            filter: drop-shadow( 0 0 10px rgba(255, 255, 255, .2))
        }
    }

    .tituloWhite {
        margin-top: 1.5rem!important;
    }
 
}